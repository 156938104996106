
.current-container{
    width: 100%;
}
.current-container::-webkit-scrollbar{
    width: 2px;
    background-color: #F5F5F5;
}
.current-container::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #F5F5F5;
}
.current-container::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
    background-color: #DBE8FD;
}
.currents{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
}
.current{
    justify-content: center;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
    animation-name: slide-in;
    animation-duration: 300ms;
    overflow: visible;
    animation: pulse 2.5s linear infinite;
    padding: 2rem;
}

.rectangle_{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid white;
    width: 100%;
    box-shadow: 10px 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    overflow: visible;
}
.name-current{
    margin: 0.5rem 0.5rem 0.5rem;
    color: white;
    font-size: 2rem;
    font-weight: 400;
    overflow: visible;
}
.title-current{
    color: white;
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0.5rem;
}
.current-img {
    /*margin-left: -4rem;*/
    padding: 0.5rem;
    width: 15rem;
    object-fit: cover;
}
.current::before{

}
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.05);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
/*@media screen and (max-width: 920px){*/
/*    .project{*/
/*        min-width: 200px ;*/
/*    }*/
/*    .rectangle{*/
/*        width: 250px;*/
/*        height: 350px;*/
/*    }*/
/*    .project:hover{*/
/*        transform: scale(1);*/
/*    }*/
/*    .title-project{*/
/*        line-height: 24px;*/
/*        font-size: 20px;*/
/*        font-weight: 300;*/
/*        bottom: 5px;*/
/*    }*/
/*}*/
.li{
    font-weight: 300;
    font-size: 30px;
    color: white;
}

.tech-title{
    font-weight: 400;
    font-size: 50px;
    color: white;
}
.tech-row{
    display: flex;
    flex-wrap: wrap;
}
.tech-col{
    margin: 30px;
}
.technology-container{
    position: relative;
    width: 100%;
    height: 1000px;
}
.sphere{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 300px;
    background: transparent;
    color: white;
    font-size: 40px;
    border: 2px solid white;
    text-align: center;

}
.group:hover{
    text-shadow: 0px 0px 26px rgba(255, 255, 255, 0.70);
    transform: scale(1.1);
}
.tech-text{
    color: white;
    font-size: 24px;
    font-weight: 300;
    border-bottom: 2px solid white;
}
.text-left{
    padding-right: 20px;
    margin-right: 10px;
}
.tech-img{
    margin-top: 40px;
}
.text-right{
    padding-left: 20px;
    margin-left: 10px;
}
.text-group-text{

}
.group{
    display: flex;
    justify-content: space-between;
    position: absolute;
    cursor: pointer;
    transition: 0.2s;
}
.group1{
    top: 147px;
}
.group1> .sphere {
    width: 286px;
    height: 286px;
    float:right;
    shape-outside: circle(50%);
}
.group2{
    right: 200px;
    top: 165px;
}
.group2> .sphere {
    width: 225px;
    height: 225px;
    font-size: 36px;
    float:right;
    shape-outside: circle(50%);
}
.group3{
    left: 450px;
    right: auto;
    top: 395px;
}
.group3> .sphere {
    width: 262px;
    height: 262px;
    float:right;
    shape-outside: circle(50%);
}
.group4{
    left: 30px;
    top: 570px;
}
.group4> .sphere {
    width: 225px;
    height: 225px;
    font-size: 36px;
    float:right;
    shape-outside: circle(50%);
}
.group5{
    right: 250px;
    top: 666px;
}
.group5> .sphere {
    width: 198px;
    height: 198px;
    float:right;
    shape-outside: circle(50%);
}
.group6{
    right: 20px;
    top: 413px;
}
.group6> .sphere {
    width: 244px;
    height: 244px;
    float:right;
    shape-outside: circle(50%);
}

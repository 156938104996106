.header-container{
    width: 100%;
}
.vanta-canvas{
    background-color: rgba(11, 13, 47, 1);
}
.counters{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
}
.counter{
    min-width: 220px;
    width: 25%;
    height: 150px;
    transition: 0.2s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 70px;
}
.name-value{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 60px;
}
.counter:hover{
    transform: scale(1.2);
    text-shadow: 0px 0px 26px rgba(255, 255, 255, 0.55);
}
.counter:hover .counter-img{
    opacity: 1;
    color: white;
}
.counter:hover .counter-value{
    opacity: 1;
    color: white;
}
.counter:hover .counter-name{
    opacity: 1;
    color: white;
}
.counter-img{
    color: #DBE8FD;
    font-size: 90px;
    opacity: 0.7;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}
.counter-value, .counter-name{
    color: #DBE8FD;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    opacity: 0.9;
}
.counter-value{
    font-size: 45px;
    font-weight: 400;
}
.line-counter{
    display: flex;
    justify-content: space-evenly;
}

.counter-value:hover, .counter-name:hover, .counter-img:hover{

}
@media screen and (max-width: 1240px){
    .counter-img{
        font-size: 50px;
    }
    .counter-value{
        font-size: 30px;
    }
    .counter-name{
        font-size: 16px;
    }
    .counter {
        min-width: 120px;
        width: 50px;
        height: 50px;
    }
    .header-container{
        width: 100%;
    }
}

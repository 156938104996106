html {
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Oswald';
    overflow-x: hidden;

}
a {
    text-decoration: none;
}
button:active{
    background-color: #DBE8FD;
}
.container{
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 10%;
    padding-right: 10%;
    overflow-block: visible;
}
.main_header{
    padding-top: 200px;
    font-weight: 500;
    font-size: 64px;
    line-height: 73px;
    color: white;
    text-shadow: 0px 0px 26px rgba(0, 0, 0, 0.70);
}
.main_text{
    font-family:  'Rubik', sans-serif;
    font-size: 1.3rem;
    color: #0B0D2F;
    font-weight: 300;
    white-space: normal;
}
.left_img{
    float:left; /* Выравнивание по левому краю */
    margin: 1rem 1rem 1rem 0; /* Отступы вокруг картинки */
}
.wrapper{
    padding: 1.5rem 0;
    background: linear-gradient(112.08deg, #ED9577 7.12%, #6D9CE7 95.91%);
    width: 100%;
}
.current_wrapper{
    padding: 0.2rem 0;
}
.wrapper_1{
    background:  linear-gradient(110.4deg, #ED9577 18.33%, #5D84C2 90.12%)
}
.wrapper_dark{
    padding: 50px 0;
    background-color: #0B0D2F;
    width: 100%;
}
.wrapper_white{
    padding: 50px 0;
    background-color: white;
    width: 100%;
}
.wrapper_projects{
    display: flex;
    align-items: center;
    justify-content: center;
}

.mini-header{
    margin: 20px 0;
    color: white;
    line-height: 30px;
    font-size: 24px;
    font-weight: 300;
}
.main_btn{
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
    color: #DBE8FD;
    font-weight: 400;
    font-size: 40px;
    line-height: 59px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 30px;
    border: 2px solid;
    margin-top: 50px;
    margin-bottom: 70px;
    font-family: 'Oswald';
    height: 90px;
    transition: 0.5s;
}
.main_btn:active{
    background: rgba(255, 255, 255, 0.3);;
}
.main_btn:hover{
    transform: scale(1.05);
    color: white;
    box-shadow: 0px 0px 26px rgba(255, 255, 255, 0.55);
    background: rgba(255, 255, 255, 0.3);
}
.header{
    font-weight: 500;
    font-size: 4rem;
}
.header-modal{
    font-size: 40px;
}
.header-mini-modal{
    line-height: 20px;
}
.light{
    color: white;
}
.dark{
    color: #5D84C2;
}
.blur{
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}
.contacts{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.contacts-col{
    margin: 10px;
}
.angle-up{
    color: white;
    font-size: 40px;
    opacity: 1;
}
.angle-btn{
    width: 50px;
    height: 50px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B4C6E5;
    border: none;
    position: fixed;
    bottom: 30px;
    right: 30px;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
}
.angle-btn:hover{
    background-color: white;
    box-shadow: 1px 2px 20px rgba(93, 132, 194, 0.6);
}
.angle-btn:hover .angle-up{
    color: #5D84C2;
}
.map{
    position: relative;
    overflow: hidden
}
.map-text{
    color: #5D84C2;
    font-size:12px;
    position:absolute;
    top:0
}
.map-address{
    color:#5D84C2;
    font-size:12px;
    position:absolute;
    top:14px
}
.lng_btn_container{
    display: flex;
    justify-content: center;
}
.lng_btn{
    border: none;
    color: white;
    background: none;
}
.lng_btn:active{
    border-bottom: white;
}
ul.alice-carousel__dots {
    margin: 0.1rem !important;
}
@media screen and (max-width: 920px){
    .main_header{
        padding-top: 100px;
        font-size: 54px;
        line-height: 63px;
    }
    .main_btn{
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .header{
        font-size: 60px;
        line-height: 60px;
    }
    .angle-up{
        font-size: 30px;
        color: white;
    }
    .angle-btn{
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 20px;
        transition: 1s;
    }
    .angle-btn:hover{
        background-color: #B4C6E5;
    }
    .angle-btn:hover .angle-up{
        color: white;
    }
}


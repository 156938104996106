.navbar-container{
    position: fixed;
    background: rgba(11, 13, 47, 0.7);
    top: 0;
    z-index: 15;
    left: 0;
    right: 0;
    padding: 10px;
    vertical-align: center;
}
.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
}
.navbar-mobile{
    display: none;
}
.burger{
    z-index: 100;
    font-size: 40px;
    color: white;
}
.burger:active{
    color: #5D84C2;
}
.navbar-items{
    display: flex;
}
.navbar-item{
    color: white;
    font-size: 20px;
    margin: 10px;
    transition: 0.2s;
    cursor: pointer;
}
.navbar-item:hover{
    transform: scale(1.1);
    text-shadow: 0px 0px 26px rgba(255, 255, 255, 0.70);
}

.logo{
    height: 30px;
}
.darken{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.37);
    z-index: 5;
}
.none-darken{
    display: none;
}

@media screen and (max-width: 1280px){
    .navbar{
        display: none;
    }
    .navbar-mobile{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-container{
        position: fixed;
        top: 0;
        padding: 20px;
        background: rgba(11, 13, 47, 0.9);
        vertical-align: center;

    }
    .navbar-items{
        padding: 0 20px;
        background: rgba(11, 13, 47, 0.9);
        position: fixed;
        top: 80px;
        left: 0;
        display:block;
        width: 70vw;
        height: 100vh;
        overflow-y: auto;
    }
    .navbar-item{
        display:block;
        color: white;
        font-size: 30px;
        font-weight: 300;
    }
}
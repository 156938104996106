/*
.modal__background{
    background-color: rgba(87, 87, 87, 0.3);
    filter: alpha(opacity=0.2);
    width: 100vw;
    height: 100vh;
    z-index: 100;
    position: absolute;
}
.modal__body{
    background-color: white;
    border: 1px solid #B4C6E5;
    width: 30vw;
    height: 20vh;
    position: fixed;
    top: 40%;
    margin-left: 35%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 120;
}*/
.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-height: 100%;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.25);
    animation-name: appear;
    animation-duration: 300ms;
}

.modal-dialog {
    width: 100%;
    max-width: 550px;
    background: white;
    position: relative;
    margin: 20px;
    padding: 30px;
    max-height: calc(100vh - 40px);
    text-align: left;
    filter: drop-shadow(0px 0px 9px rgba(32, 40, 77, 0.27));
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: slide-in;
    animation-duration: 0.5s;
    border: 2px solid #B4C6E5;
    border-radius: 15px;
    overflow: auto;
}
.modal-dialog-submit{
    border: 2px solid rgba(255, 0, 0, 0.13);
}
.modal-header {
    justify-content: space-between;
}
.modal-footer {
    justify-content: flex-end;
}
.modal-close {
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.modal-body {

}
.submit{
    display: flex;
    justify-content: center;
}
.modal-content {
    padding: 1rem;
    text-align: center;
}
.triangle{
    color: #930008;
}
.modal-content-submit{
    color: #930008;
    background-color: rgba(255, 0, 0, 0.13);
    display: flex;
    justify-content: center;
    white-space: pre-wrap
}
.myInput{
    border: none;
    border-bottom: 2px solid #B4C6E5;
    width: 100%;
    font-size: 24px;
}
.myInput:focus{
    border-color: white;
}
.myText{
    width: 100%;
    border: 2px solid #B4C6E5;
    font-size: 24px;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-in {
    from {
        transform: translateY(-150px);
    }
    to {
        transform: translateY(0);
    }
}

.modal-btn{
    background: white;
    border-radius: 7px;
    color: #5D84C2;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 20px;
    border: 2px solid;
    margin: 1rem;
    font-family: 'Oswald';
    transition: 0.5s;
}
.modal-btn:active{
    background: rgba(255, 255, 255, 0.3);;
}
.modal-btn:hover{
    transform: scale(1.05);
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.14);
    background:  linear-gradient(110.4deg, #ED9577 1.33%, #5D84C2 100.12%);
    color: white;
    border: none ;



}

